import API from "../services/services";

class ManageBloodInService {
  getAllQuarantine(params) {
    return API.get("blood_in/list_quarantine", { params });
  }
  add(payload) {
    return API.post("blood_in/list/store", payload);
  }
  delete(payload) {
    return API.post("blood_in/list/delete", payload);
  }
  getDetail(id) {
    return API.get("blood_in/detail/" + id);
  }
  edit(payload) {
    return API.post("blood_in/list/update", payload);
  }
  approvedBloodIn(payload) {
    return API.post("blood_in/approved_blood_in", payload);
  }
}

export default new ManageBloodInService();
