<template>
  <div class="container">
    <section class="bg-white p-3 rounded shadow-sm">
      <b-row class="align-items-center">
        <b-col cols="6">
          <b-form-group
            label="Filter Berdasarkan Lokasi MU :"
            label-for="filter-mu"
          >
            <v-select
              id="filter-mu"
              placeholder="Pilih Lokasi Mobil Unit"
              :options="listMU"
              label="name"
              v-model="selectedMU"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Pilih Tanggal" label-for="start-date">
            <b-form-input id="start-date" type="date" v-model="selectedDate" />
          </b-form-group>
        </b-col>
      </b-row>
    </section>

    <section class="mt-3 bg-white p-3 rounded shadow-sm">
      <h3 class="text-center mb-3 p-1">
        {{ donorInfo.MU }} : {{ donorInfo.date }}
      </h3>
      <p class="mb-0 p-1">Tempat Donor: {{ donorInfo.place }}</p>
      <p class="p-1">Jumlah Donor Disetujui: {{ donorInfo.approved }}</p>

      <div v-for="(bagType, index) in bloodBags" :key="index">
        <h5 class="m-0 p-1">Jenis Kantong : {{ bagType.name }}</h5>
        <table class="table table-sm table-bordered">
          <tr class="text-center">
            <td v-for="(bloodType, bIndex) in bagType.bloodTypes" :key="bIndex">
              <p>{{ bloodType.name }}</p>
              <h4 class="font-weight-bold">
                {{ bloodType.quantity }}
              </h4>
            </td>
          </tr>
        </table>
        <p class="text-right font-weight-bold">Total: {{ bagType.total }}</p>
      </div>
    </section>

    <section class="mt-3 bg-white p-3 rounded shadow-sm">
      <h3>Daftar Darah</h3>
      <b-table
        striped
        hover
        small
        show-empty
        responsive
        bordered
        :items="table.items"
        :fields="table.fields"
        :current-page="table.currentPage"
        :per-page="table.perPage"
      >
        <template #cell(blood_type)="data">
          {{ formatBloodType(data.item.blood_type) }}
        </template>
        <template #cell(terima)="data">
          <b-form-checkbox
            v-model="data.item.terima"
            :checked="data.item.status_collected === 1"
            @change="updateTerima(data.item.barcode, $event)"
          >
          </b-form-checkbox>
        </template>
      </b-table>
      <div class="d-flex justify-content-end">
        <base-button
          variant="primary-1"
          class="mt-2"
          text="Simpan"
          @click="showModal = !showModal"
        />
      </div>
    </section>

    <b-modal
      v-model="showModal"
      title="Konfirmasi Darah Masuk"
      @ok="handleSubmit"
    >
      <b-form-group label="Nama Pengirim">
        <b-form-input
          v-model="form.namaPengirim"
          placeholder="Masukkan Nama Pengirim"
        >
        </b-form-input>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import vSelect from "vue-select";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BTable,
  BFormCheckbox,
  BModal,
} from "bootstrap-vue";
import manageBloodvanAPI from "../../../api/managebloodvan/manageBloodvanAPI";
import manageAftafAPI from "../../../api/manageAftaf/manageAftafAPI";
import manageBloodInAPI from "../../../api/bloodIn/manageBloodInAPI";

export default {
  name: "PengumpulanDarah",
  components: {
    vSelect,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BTable,
    BFormCheckbox,
    BModal,
  },
  data() {
    return {
      listMU: [],
      selectedMU: null,
      selectedDate: "",
      bloodBags: [
        {
          name: "Double (DB)",
          bloodTypes: ["A+", "B+", "AB+", "O+"].map((type) => ({
            name: this.formatBloodType(type),
            quantity: 0,
          })),
          total: 0,
        },
        {
          name: "Triple (TR)",
          bloodTypes: ["A+", "B+", "AB+", "O+"].map((type) => ({
            name: this.formatBloodType(type),
            quantity: 0,
          })),
          total: 0,
        },
      ],
      donorInfo: { date: "", place: "", MU: "", approved: 0 },
      table: {
        fields: [
          { key: "barcode", label: "Barcode", sortable: true },
          { key: "blood_bag_number", label: "No. Selang", sortable: true },
          { key: "bag_type", label: "Jenis Kantong", sortable: true },
          { key: "blood_type", label: "Gol. Darah", sortable: true },
          { key: "blood_courier", label: "Pengirim", sortable: true },
          { key: "officer_name", label: "Penerima", sortable: true },
          { key: "terima", label: "Terima" },
        ],
        items: [],
        currentPage: 1,
        perPage: 500,
      },
      showModal: false,
      form: {
        namaPengirim: "",
      },
      checkedItems: new Set(),
    };
  },
  methods: {
    async fetchListsMU() {
      try {
        const { data: response } = await manageBloodvanAPI.getAll();
        this.listMU = response.data.data;
      } catch (error) {
        console.error("Failed to fetch MU list", error);
      }
    },
    async getAftapExamination() {
      if (!this.selectedMU || !this.selectedDate) return;

      try {
        const { data: response } = await manageAftafAPI.collectBlood({
          date: this.selectedDate,
          blood_van_id: this.selectedMU.id,
        });

        if (response.success) {
          this.updateBloodBags(response.grouped);
          this.updateDonorInfo(response);
          this.table.items = response.data.map((item) => ({
            ...item,
            terima: item.status_collected === 1,
          }));
          this.checkedItems = new Set(
            response.data
              .filter((item) => item.status_collected === 1)
              .map((item) => item.barcode)
          );
        } else {
          console.error("API request failed:", response.message);
        }
      } catch (error) {
        console.error("Failed to fetch Aftap Examination list", error);
      }
    },
    updateBloodBags(groupedData) {
      this.bloodBags.forEach((bag) => {
        bag.bloodTypes.forEach((type) => (type.quantity = 0));
        bag.total = 0;
      });

      Object.entries(groupedData).forEach(([bagType, bloodTypes]) => {
        const bagIndex = bagType === "DB" ? 0 : 1;
        Object.entries(bloodTypes).forEach(([bloodType, quantity]) => {
          const formattedTypeName = this.formatBloodType(bloodType);
          const typeObj = this.bloodBags[bagIndex].bloodTypes.find(
            (type) => type.name === formattedTypeName
          );
          if (typeObj) {
            typeObj.quantity += quantity;
            this.bloodBags[bagIndex].total += quantity;
          }
        });
      });
    },
    updateDonorInfo(data) {
      this.donorInfo = {
        date: data.donor_date,
        place: data.donor_place,
        MU: data.MU,
        approved: data.donor_approved,
      };
    },
    formatBloodType(type) {
      return type.replace(/\+|-/g, "") + (type.includes("+") ? " Pos" : " Neg");
    },
    updateTerima(barcode, isChecked) {
      if (isChecked) {
        this.checkedItems.add(barcode);
      } else {
        this.checkedItems.delete(barcode);
      }
    },
    async handleSubmit() {
      try {
        const newlyCheckedItems = Array.from(this.checkedItems).filter(
          (barcode) =>
            !this.table.items.find(
              (item) => item.barcode === barcode && item.status_collected === 1
            )
        );

        if (newlyCheckedItems.length > 0) {
          await manageBloodInAPI.approvedBloodIn({
            blood_courier: this.form.namaPengirim,
            barcodes: newlyCheckedItems,
            note: "",
          });
          // Update status_collected for newly checked items
          this.table.items = this.table.items.map((item) => {
            if (newlyCheckedItems.includes(item.barcode)) {
              return { ...item, status_collected: 1 };
            }
            return item;
          });

          // update tabelnya
          this.table.items = this.table.items.map((item) => {
            if (newlyCheckedItems.includes(item.barcode)) {
              return {
                ...item,
                terima: true,
                blood_courier: this.form.namaPengirim,
                officer_name: JSON.parse(localStorage.getItem("userInfo")).name,
              };
            }
            return item;
          });

          this.$bvToast.toast("Pengumpulan darah disetujui", {
            title: "Success",
            variant: "success",
            solid: true,
          });
        } else {
          this.$bvToast.toast("Tidak ada item yang diperbarui", {
            title: "Warning",
            variant: "warning",
            solid: true,
          });
        }

        this.showModal = false;
      } catch (error) {
        console.error("Failed to approve blood in:", error);
        this.$bvToast.toast("Gagal menyetujui pengumpulan darah", {
          title: "Gagal",
          variant: "danger",
          solid: true,
        });
      }
    },
  },
  mounted() {
    this.fetchListsMU();
  },
  watch: {
    selectedMU: "getAftapExamination",
    selectedDate: "getAftapExamination",
  },
};
</script>

<style scoped>
.table td p {
  margin-bottom: 0;
}
</style>
